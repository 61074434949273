import React, { useEffect } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import Seo from "../components/seo"
import {StaticImage} from "gatsby-plugin-image"
import {SectionHero, Section, Container, SectionTitle, SectionDescription, SectionPageTitle, SectionImageBkg, ListPointer, ListItemPointer } from "../components/Section"
import {LineArrowDownIcon} from "../components/Icons"
import BlueVector from "../images/blue-pattern.jpg"
import CheckList from "../images/checklist.png"

const SectionBanner = styled(SectionHero)`
	position:relative;
    height: 100%;
    &:after{
        content: "";
        display:block;
        position: absolute;
        left: 0;
        top: 0;
        background: linear-gradient(90deg, #00000066 0%, #00000000 100%);
        width:100%;
        height: 100%;    
    }
`
const HeroCaption = styled.div`	
    width: 100%;
    padding:50px 0 70px;
    @media (min-width:768px){
        padding:70px 0 90px;
    }
    @media (min-width:992px){
        padding:90px 0 110px;
    }
    @media (min-width:1200px){
        padding:110px 0 130px;
    }
    @media (min-width:1600px){
        padding:130px 0 150px;
    }
    h1{
        @media (min-width:1440px){
            max-width:535px;
        }
    }
	
`

const CardGrid = styled.div`
    max-width:1370px;
    width:100%;
    margin:0 auto;
`
const CardGridItem = styled.div`
    &.card-grid-item{
        + .card-grid-item{
            margin-top:20px;
        }
    }
`
const CardIcon = styled.div`
   background-color:#fff;
   border-radius:20px;
   box-shadow:0 0 99px rgba(0,0,0,0.16);
   padding:20px;
   @media(min-width:768px){
    padding:30px;
   }
   @media(min-width:1200px){
    padding:40px;
   }
   @media(min-width:1600px){
    padding:60px;
   }
    
`
const CardIconBody = styled.div`
    display:flex;
    flex-wrap:wrap;
    margin:0 -15px;
    flex-direction:column-reverse;
    @media(min-width:768px){
        flex-direction:row;
    }
    @media(min-width:1200px){
        margin:0 -25px;
    }
    p{
        color:#000;
        font-size:16px;
        line-height:30px; 
        @media(min-width:1200px){
            font-size:18px;
            line-height:32px; 
        }
        @media(min-width:1600px){
            font-size:20px;
            line-height:34px; 
        }
        mark{
            background: #1383C1;
            color: #fff;
            padding: 3px 10px;
        }
    }
`
const CardIconFigure = styled.div`
    position:relative;
    width:100%;
    padding:0 15px;
    display:flex;
    align-items:center;
    margin-bottom:15px;
    @media(min-width:768px){
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
        margin-bottom:0;
    }
    @media(min-width:1200px){
        padding:0 25px;
    }
`
const CardIconContent = styled.div`
    position:relative;
    width:100%;
    padding:0 15px;
    @media(min-width:768px){
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }
    @media(min-width:1200px){
        padding:0 25px;
    }

`

const SectionGrid = styled.div`
    display:flex;
    flex-wrap:wrap;
    align-items:center;
    position:relative;
    margin:0 -15px;
    @media(min-width:992px){
        margin:0 -30px;
    }
    
`
const SectionGridLeft = styled.div`
    position: relative;
    width: 100%;
    padding:0 15px;
    @media(min-width:768px){
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }
    @media(min-width:992px){
        padding:0 30px;
    }

`
const SectionGridRight = styled.div`
    position: relative;
    width: 100%;
    padding:0 15px;
    @media(min-width:768px){
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }
    @media(min-width:992px){
        padding:0 30px;
    }
`
const SiteLevel = styled.div`
    text-align:center;
`
const SiteItemLevel = styled.div`
    + div{
        border-top:1px solid #D8D8D8;
        padding-top:30px;
        margin-top:30px;
        @media(min-width:992px){
            padding-top:40px;
            margin-top:40px;
        }
        @media(min-width:1200px){
            padding-top:60px;
            margin-top:60px;
        }
        @media(min-width:1600px){
            padding-top:80px;
            margin-top:80px;
        }
    }
    .gatsby-image-wrapper{
        display:inline-block;
    }
`
const SiteLevelTitle = styled.p`
    width:100%;
    max-width:980px;
    margin:0 auto ${props=>props.mb} auto;

    font-size:20px;
    line-height:30px; 
    @media(min-width:1200px){
        font-size:22px;
        line-height:40px;
    }
    @media(min-width:1600px){
        font-size:30px;
        line-height:40px; 
    }
`

const BlueBackground = styled.div`    
    display: flex;
    align-items: center;
    height: 100%;
    background-color:#fff;
    .container{
        position:relative;
    }
    &:after, &:before{
		content: "";
		position: absolute;
        width:100%;
		height:100%;
        top:0;
        left:0;
        clip-path: ellipse(100% 100% at 50% 0);
       
	}
    &:before{
		background: transparent linear-gradient(270deg, #1383C1 0%, #006EAC 100%) 0% 0% no-repeat padding-box;
        filter: drop-shadow(0 0 30px rgba(0,0,0,0.6));
	}
	&:after{
		background:${props => props.bgAfter};
		background-repeat: no-repeat;
		background-size: cover;
		opacity: 0.15;
		background-position: center right;
	}	
`
const LevelOption = styled.div`
    display:flex;
    flex-wrap:wrap;
    margin:0 -1px;
    padding: 0 50px;
`
const LevelOptionItem = styled.div`
    position: relative;
    width: 100%;
    padding:0 1px;
    + div{
        margin-top:30px;
        @media(min-width:576px){
            margin-top:0;
        }
    }
    @media(min-width:576px){
        flex: 0 0 50%;
        max-width: 50%;
    }
`

const MediaLevel = styled.div`
    position:relative;

`
const MediaLevelBody = styled.div`
    position:relative;
    .gatsby-image-wrapper{
        display:block;
    }

`
const MediaLevelFigure = styled.div`
    position:absolute;
    bottom:0;
    border-radius:50%;
    border:4px solid #000;
    width:80px;
    height:80px;
    right:0;	
    @media(min-width:576px){
        left: ${props=>props.left};	
        right: ${props=>props.right};	
    }
    @media(min-width:992px){
        width:100px;
        height:100px;
    }
    @media(min-width:992px){
        width:140px;
        height:140px;
    }
    @media(min-width:1440px){
        width:180px;
        height:180px;
    }
`
const MediaLevelTitle = styled.div`
    text-align:center;
    color:#000;
    text-transform:uppercase;
    margin-top:20px !important;
`
const Installation = styled.div`
    max-width:980px;
    width:100%;
    margin:0 auto;
    text-align:center;
    p{
        margin-bottom:30px;
        @media(min-width:1200px){
            margin-bottom:60px;
        }
        @media(min-width:1600px){
            margin-bottom:80px;
        }
        strong{
            font-size:20px;
            line-height:30px; 
            @media(min-width:1200px){
                font-size:22px;
                line-height:40px;
            }
            @media(min-width:1600px){
                font-size:30px;
                line-height:40px; 
            }
        }
    }

`

const MediaList = styled.div`
    display:flex;
    flex-wrap:wrap;
    margin:0 -15px;
`
const MediaListItem = styled.div`
    position: relative;
    padding:0 15px;
    width: 100%;    
    + div{
        margin-top:30px;
    }
    @media(min-width:576px){
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
        + div{
            margin-top:0;
        }
    }
`
const MediaWork = styled.div`
`
const MediaWorkBody = styled.div`
`
const MediaWorkFigure = styled.div`
    margin-bottom:15px;
    text-align:center;
    .gatsby-image-wrapper{
        display:inline-block;
    }
`
const MediaWorkTitle = styled.div`
    text-align:center;
    color:#000;
    text-transform:uppercase;
`    
    
const CardCheck = styled.div`   
    background-color:#F8FDFE;
    border-radius:20px 20px 0 0;
    box-shadow:0 0 99px rgba(0,0,0,0.2);

`
const CardCheckBody = styled.div`
    padding:30px;
    @media(min-width:768px){
        padding:40px;
    }
    @media(min-width:992px){
        padding:70px 60px;
    }
    @media(min-width:1200px){
        padding:70px 120px;
    }
`
const CardCheckTitle = styled.div`
    color:#000;
    text-transform:uppercase;
    text-align:center;
`
const Sections = styled(Section)`
    border-bottom:1px solid #ccc;
`

const ListChecklist = styled.ul`
	list-style: none;
	margin:0 0 30px;
	padding:0;
	color:#000;
	font-size:16px;
	line-height:30px; 
	@media(min-width:1200px){
		font-size:18px;
	    line-height:34px; 
	}
	@media(min-width:1600px){
		font-size:20px;
		line-height:36px; 
	}
`
	
const ListItemChecklist = styled.li`
	padding-left:40px;
	vertical-align: text-top;
	margin:0;
	+ li{
        margin-top:15px;
        @media(min-width:768px){
		    margin-top:30px;
        }
        @media(min-width:1200px){
		    margin-top:40px;
        }
        @media(min-width:1600px){
		    margin-top:50px;
        }
	}
	&:before {
		content: url(${CheckList});
		display: inline-block;
		vertical-align: text-top;
		margin-left: -40px;
		width: 40px;
	}
	
`
const CheckListGrid = styled.div`
    display:flex;
    flex-wrap:wrap;
    align-items: flex-end;
    position:relative;	
`
const CheckListItem = styled.div`
    position:relative;
    width: 100%;
    @media(min-width:1200px){
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
        margin-left:16.666667%;
    }
`
const Character = styled.div`
    position: absolute;
    right: calc(100% - 125px);
    bottom: 10px;
    width: 100%;
    display:none;
    @media(min-width:1200px){
        display:block;
        max-width: 290px;

    }
    @media(min-width:1600px){
        max-width: 390px;

    }

`


const RequirementBackground = styled.div`    
    width:100%;
    max-width:320px;
    @media (min-width: 992px) {
        max-width:450px;
    }
    @media (min-width: 1200px) {
        max-width:560px;
    }
    @media (min-width: 1440px) {
        max-width:620px;
    }
    @media (min-width: 1600px) {
        max-width:720px;
    }
    &:after, &:before{
		content: "";
		position: absolute;
        width:100%;
		height:100%;
		clip-path: ellipse(90% 100% at 0% 50%);
        top:0;
        left:0;
	}
    &:before{
		background: transparent linear-gradient(270deg, #1383C1 0%, #006EAC 100%) 0% 0% no-repeat padding-box;		
	}
	&:after{
		background:${props => props.bgAfter};
		background-repeat: no-repeat;
		background-size: cover;
		opacity: 0.15;
		background-position: center right;
	}	
`
const Requirement = styled.div`
    position: relative;
    @media (min-width: 992px) {
        display: grid;
        align-items:center;
        grid-template-columns: 2fr 1fr;
    }
`

const RequirementLeft = styled.div`
    position: relative;    
    filter: drop-shadow(0 0 40px rgba(0, 0, 0, 0.4));    
    display:none;
    @media (min-width: 992px) {
        padding: 90px 60px 90px 30px;
        display:block;
    }
    @media (min-width: 1200px) {
        padding: 120px 60px 120px 30px;
    }
    @media (min-width: 1600px) {
        padding: 180px 60px 180px 30px;
    }
    @media (min-width: 1650px) {
        padding: 180px 60px 180px calc(50vw - 810px);
    }   
`
const RequirementRight = styled.div`
    position: relative;   
    padding: 0 15px;
    @media (min-width: 992px) {
        padding: 40px 30px 40px 30px;  
    }
    @media (min-width: 1200px) {
        padding: 40px 30px 40px 30px;  
    }
    @media (min-width: 1600px) {
        padding: 60px 30px 60px 30px;  
    }
    @media (min-width: 1650px) {       
        padding: 60px calc(50vw - 810px) 60px 30px;  
    }
`
const RequirementPoint = styled.div`
    position: relative;
    z-index:1;
    border-top:1px solid rgba(255,255,255,.4);
    counter-reset:section;    
    max-width:720px;
    width:100%;
    color:#fff;
`
const RequirementItemPoint = styled.div`
    border-bottom:1px solid rgba(255,255,255,.4);
    position:relative;
    margin-bottom:0 !important;
    display:flex;
    align-items:center;
    justify-content:space-between;
    cursor:pointer;
    text-transform:uppercase;
    padding:30px 25px 30px 40px;
    @media (min-width: 768px) {
        padding:30px 25px 30px 60px;
    }
    @media (min-width: 992px) {
        padding:30px 25px 30px 80px;
    }
    @media (min-width: 1600px) {
        padding:50px 25px 50px 140px;
    }
    svg{
        height: 20px;
        width: 20px;
        fill: #fff;
        position: absolute;
        right: 0;
        pointer-events: none;
    }
    &:before {
        counter-increment: section;
        content: counter(section);
        font-family: 'Teko',sans-serif;
        font-weight: 700;
        color: rgba(255,255,255,0.3);
        position: absolute;
        top:40px;
        left:0;

        font-size: 30px;
        line-height: 30px;
        @media (min-width: 768px) {
            font-size: 70px;
            line-height: 30px;
        }
        @media (min-width: 992px) {
            font-size: 110px;
            line-height: 70px;
        }
        @media (min-width: 1600px) {
            font-size: 190px;
            line-height: 150px;
        }
    }
`

const Alert = styled.div`
    background-color: #000;
    color: #fff;
    border-radius: 20px;
    padding: 30px;
    width:100%;
    @media (min-width: 992px) {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: -50%;
        max-width: 810px;
        transform: translateY(-50%);
        right:15px;
        padding:40px;
        right:30px;
    }
    @media (min-width: 1200px) {
        padding:60px;
    }
    @media (min-width: 1600px) {
        padding:80px;
    }

`
const AlertTitle = styled.div`
    display:flex;
    flex-wrap:wrap;
    align-items:center;
    margin:0 -10px 10px -10px;
    .text{
        text-transform:uppercase;
        margin-bottom:0 !important;
    }
    .text, .icon{
        position:relative;
        padding:0 10px;
    }
`
const AlertBody = styled.div`
    > p{
        color:#fff;
    }
`
const SectionParallax = styled(Section)`
        padding:0 0 40px 0;
    @media(min-width:768px){        
        position:sticky;
        top:0;
        z-index:0;
    }
    @media (min-width: 1200px) {
        padding:0 0 60px 0;
    }
    @media (min-width: 1600px) {
        padding:0 0 80px 0;
    }
`

const SitePreparationPage = ({ location, data }) => {

    useEffect(() => {
        document.addEventListener('click', function(event) {
            if (!event.target.matches('.btn-scroll-into')) return;
            event.preventDefault();
            
            var element = document.getElementById(event.target.dataset.target);
            
            element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
        
        });
    }, [])

    return(
        <Layout location={location}>
            <Seo
                title={data.allContentfulPageData.edges[0].node.metaTitle}
                description={data.allContentfulPageData.edges[0].node.metaDescription.metaDescription}
            />
            <SectionBanner className="section-hero">
                <SectionImageBkg><StaticImage src="../images/site-hero.jpg" alt="Site Hero" /></SectionImageBkg>
                <HeroCaption>
                    <div className="container">
                        <SectionPageTitle className="h2" color="#fff" textAlign="left" mb="0" ml="0">SITE PREPARATION & INSTALLATION</SectionPageTitle>
                    </div>
                </HeroCaption>
            </SectionBanner>

            <Section mt="-60px" pt="0" pb="120px" xpb="60px" mpb="40px" bgColor="transparent" className="section-installation">       
                <div className="container">           
                    <CardGrid className="card-grid">
                        <CardGridItem className="card-grid-item">
                            <CardIcon>
                                <CardIconBody>
                                    <CardIconContent>                                
                                        <p>The location of where the metal building will be installed must be level within 1” from front to back and side to  side, as well as being squared for proper installation. In order to avoid incurring any additional fee, make sure your surface is level to begin with. If your building has roll-up doors, then any side where a roll-up door is to be installed must be completely level in order to avoid any cracks/openings beneath the door. </p>
                                    </CardIconContent>
                                    <CardIconFigure><StaticImage src="../images/ground.png" alt="ground" /></CardIconFigure>
                                </CardIconBody>
                            </CardIcon>
                        </CardGridItem>
    
                        <CardGridItem className="card-grid-item">
                            <CardIcon>
                                <CardIconBody>
                                    <CardIconContent>                                
                                        <p>Installation crews typically arrive in a dually pick-up truck with attached trailer, total rig length is between 65’-70’. Therefore, remote locations, steep hills, or sites located far off the road may be inaccessible. The decision to install or not in these types of instances remains at the sole discretion of the installation crew. In addition, installation crews require a minimum of 4’ clearance around all sides of the structure.</p>
                                    </CardIconContent>
                                    <CardIconFigure><StaticImage src="../images/truck.png" alt="truck" /></CardIconFigure>
                                </CardIconBody>
                            </CardIcon>
                        </CardGridItem>
    
                        <CardGridItem className="card-grid-item">
                            <CardIcon>
                                <CardIconBody>
                                    <CardIconContent>
                                        <p>Any requirements or safety training/videos to be completed prior to installation must be disclosed to your sales representative upon purchase of the unit. Such requirements must be noted on the purchase order. An additional fee may be incurred, depending on the length and depth of any training or other requirements. </p>
                                    </CardIconContent>
                                    <CardIconFigure><StaticImage src="../images/safety.png" alt="safety" /></CardIconFigure>
                                </CardIconBody>
                            </CardIcon>
                        </CardGridItem>
                    </CardGrid>
                </div>
            </Section>
    
            <Section pt="100px" pb="100px" xpt="60px" xpb="60px" mpt="40px" mpb="40px" bgColor="#000" className="section-requirement">	
                <SectionImageBkg opacity="0.6"><StaticImage src="../images/requirement-hero.jpg" alt="Requirement" /></SectionImageBkg>
                <div className="container">
                    <SectionTitle textAlign="center" color="#fff" mb="0" maxWidth="795px">IF YOU ARE UNSURE THAT YOU MEET THESE REQUIREMENTS</SectionTitle>
                    <SectionDescription textAlign="center" color="#fff" mb="0">
                        <p>Contact us today at <a href="tel:3869610006" aria-label="phone number" class="text-underline">386-961-0006</a></p>
                    </SectionDescription>
                </div>
            </Section>
    
            <Section pt="100px" pb="100px" xpt="60px" xpb="60px" mpt="40px" mpb="40px" bgColor="#fff" className="section-potential"> 
                <div className="container">
                    <SectionGrid>
                        <SectionGridLeft>
                            <SectionTitle mb="0">POTENTIAL FEES</SectionTitle>
                            <SectionDescription fontStyle="italic">
                                <p>A restocking fee of $500 or 10% of the building price. (whichever is higher) will be added to the final balance of the building in the event of (but not limited to) any of the following situations:</p>
                            </SectionDescription>
                        </SectionGridLeft>
                        <SectionGridRight>
                            <ListPointer fontWeight="700" mb="0">
                                <ListItemPointer>Customer cancellation of order, or rescheduling of installation.  </ListItemPointer>
                                <ListItemPointer>Incorrect or off-square concrete pad resulting in non-installation of building.  </ListItemPointer>
                                <ListItemPointer>Alterations made to the building order after the installation date has been scheduled.  </ListItemPointer>
                                <ListItemPointer>Permit issues that result in work delays or incompletion.</ListItemPointer>
                            </ListPointer>
                        </SectionGridRight>
                    </SectionGrid>
                </div>
            </Section>
    
            <SectionParallax pt="0" pb="0" bgColor="#fff" className="section-req-building"> 
                <Requirement>
                    <RequirementLeft>
                        <RequirementBackground bgAfter={`url(${BlueVector})`}>
                            <SectionTitle color="#fff">SITE REQUIREMENTS FOR YOUR BUILDING INSTALL…</SectionTitle>
                            <RequirementPoint>
                                <RequirementItemPoint className="h4 btn-scroll-into" data-target="section-1">
                                    Is your site properly leveled?
                                    <LineArrowDownIcon />
                                </RequirementItemPoint>
                                <RequirementItemPoint className="h4 btn-scroll-into" data-target="section-2">
                                    Is your site accessible for the installation crew?
                                    <LineArrowDownIcon />
                                </RequirementItemPoint>
                            </RequirementPoint>
                        </RequirementBackground>
                    </RequirementLeft>
                    <RequirementRight>
                        <Alert>
                            <AlertTitle>
                                <i className="icon"><StaticImage src="../images/complain.png" alt="complain" /></i>
                                <span className="text h4">WARNING:</span>
                            </AlertTitle>
                            <AlertBody>
                                <p>If your site doesn’t meet the site requirements described here, YOU WILL BE CHARGED A RESTOCKING FEE, and your installation will be delayed. It is the responsibility of the customer to ensure that the installation site is properly leveled and accessible at the time of installation. If you have any questions about your site or anything else, please reach out to your sales representative.</p>
                            </AlertBody>
                        </Alert>
                    </RequirementRight>
                </Requirement>
            </SectionParallax>
    
            <Section zIndex="2" pt="100px" pb="0" xpt="100px" mpt="100px" bgColor="#fff" className="section-leveled"  id="section-1">
                <BlueBackground bgAfter={`url(${BlueVector})`}>
                    <Container zIndex="2">
                        <SectionTitle color="#fff" textAlign="center" maxWidth="580px">Is your site properly leveled?</SectionTitle>
                        <StaticImage src="../images/leveled.jpg" alt="leveled" />
                    </Container>
                </BlueBackground>
            </Section>
    
            <Section zIndex="2" pt="100px" pb="100px" xpt="60px" xpb="60px" mpt="40px" mpb="40px" bgColor="#fff" className="section-leveled">     
                <div className="container">
                    <SiteLevel >
                        <SiteItemLevel>                    
                            <SiteLevelTitle><strong>Your site must be properly leveled for installation.</strong></SiteLevelTitle>
                            <p>If your site isn’t level, this can result in gaps under doors, corners, etc.   </p>
                            <StaticImage src="../images/properly-leveled.png" alt="properly leveled" />
                        </SiteItemLevel>
                        <SiteItemLevel>
                            <SiteLevelTitle mb="30px"><strong>In addition, a minimum of 4’ level clearance is needed around the entire building (clear of any vegetation, debris, or power lines) for installation. </strong></SiteLevelTitle>
                            <StaticImage src="../images/clearance-level.png" alt="clearance-level" />
                        </SiteItemLevel>
                        <SiteItemLevel>
                            <SiteLevelTitle mb="30px"><strong>Leveling Options</strong></SiteLevelTitle>
                            <LevelOption>
                                <LevelOptionItem>
                                    <MediaLevel>
                                        <MediaLevelBody>
                                            <StaticImage src="../images/concrete-slab-building.jpg" alt="concrete-slab-building" />
                                            <MediaLevelFigure left="-50px"><StaticImage src="../images/concrete-slab.png" alt="concrete-slab" /></MediaLevelFigure>
                                        </MediaLevelBody>
                                        <MediaLevelTitle className="h4">Concrete Slab</MediaLevelTitle>
                                    </MediaLevel>
                                </LevelOptionItem>
    
                                <LevelOptionItem>
                                    <MediaLevel >
                                        <MediaLevelBody>
                                            <StaticImage src="../images/level-ground-building.jpg" alt="level-ground-building" />
                                            <MediaLevelFigure right="-50px"><StaticImage src="../images/level-ground.png" alt="level-ground" /></MediaLevelFigure>
                                        </MediaLevelBody>
                                        <MediaLevelTitle className="h4">Level Ground</MediaLevelTitle>
                                    </MediaLevel>
                                </LevelOptionItem>
                            </LevelOption>
                        </SiteItemLevel>
                    </SiteLevel>
                </div>
            </Section>
    
    
            <Section zIndex="2" pt="100px" pb="0" xpt="60px" mpt="40px" bgColor="#fff" className="section-installation"  id="section-2">
                <BlueBackground bgAfter={`url(${BlueVector})`}>
                    <Container zIndex="2">
                        <SectionTitle color="#fff" textAlign="center" maxWidth="850px">Is your site accessible for the installation crew?  </SectionTitle>
                        <StaticImage src="../images/installation-crew.png" alt="installation-crew" />
                    </Container>
                </BlueBackground>
            </Section>
    
            <Section zIndex="2" pt="60px" pb="60px" xpt="60px" xpb="60px" mpt="40px" mpb="40px" bgColor="#fff" className="section-installation">     
                <div className="container">
                    <Installation>
                        <p>It is the responsibility of the customer to ensure that the installation site is accessible to the crew delivering and installing the building. If the installation crew is unable to install a building due to an inaccessible workspace, the customer will be billed a return trip fee along with a restocking fee that must be paid before returning to the site.</p>
    
                        <p><strong>Is there a sharp curve, steep hill, or any restricted workspace?</strong> <br/>Any roads leading to your install site must be able to accommodate our installation crew vehicles. You must also have adequate room for the install crew to be able to reach your job site, unload, and turn around. Sharp curves, steep hills, and a limited workspace could hinder work crews, thus preventing your installation.</p>
                        <MediaList>
                            <MediaListItem>
                                <MediaWork>
                                    <MediaWorkBody>
                                        <MediaWorkFigure><StaticImage src="../images/sharp-curve.png" alt="sharp-curve" /></MediaWorkFigure>
                                        <MediaWorkTitle className="h4">SHARP CURVE</MediaWorkTitle>
                                    </MediaWorkBody>
                                </MediaWork>
                            </MediaListItem>
    
                            <MediaListItem>
                                <MediaWork >
                                    <MediaWorkBody>
                                        <MediaWorkFigure><StaticImage src="../images/steep-hill.png" alt="steep-hill" /></MediaWorkFigure>
                                        <MediaWorkTitle className="h4">STEEP HILL</MediaWorkTitle>
                                    </MediaWorkBody>
                                </MediaWork>
                            </MediaListItem>
                            
                            <MediaListItem>
                                <MediaWork >
                                    <MediaWorkBody>
                                        <MediaWorkFigure><StaticImage src="../images/limited-space.png" alt="limited-space" /></MediaWorkFigure>
                                        <MediaWorkTitle className="h4">LIMITED SPACE</MediaWorkTitle>
                                    </MediaWorkBody>
                                </MediaWork>
                            </MediaListItem>
                        </MediaList>
                    </Installation>
                </div>
            </Section>
    
            <Sections zIndex="2" pt="0" pb="0" bgColor="#fff" className="section-pre-checklist">
                <div className="container">
                    <CheckListGrid>
                        <CheckListItem>
                            <Character>
                                <StaticImage src="../images/mascot-watermark.png" alt="mascot-watermark" />
                            </Character>
                            <CardCheck>
                                <CardCheckBody>
                                    <CardCheckTitle className="h4">PRE-INSTALLATION CHECKLIST</CardCheckTitle>
                                    <ListChecklist>
                                        <ListItemChecklist>
                                        Prepare a level lot – Lot must be level in order to receive installation and delivery free of charge, and in order to avoid any additional labor costs.
                                        </ListItemChecklist>
                                        
                                        <ListItemChecklist>Mark any utility lines running underneath or near your planned installation site. If you don’t know where the utility lines run, call 811 to come mark the lines free of charge. You want to make sure there aren’t any power lines buried under your metal building installation site.</ListItemChecklist>
                                        
                                        <ListItemChecklist>If you’re planning to have your building installed on concrete retaining walls, please contact our corporate office to discuss installation options, as these installs are addressed on a case-by-case basis. <a href="tel:3869610006">386-961-0006</a></ListItemChecklist>
    
                                        <ListItemChecklist>If you’re pouring a concrete pad, make the length 1’ shorter than your building. For example, a 20’ x 26’ garage will need a 20’ x 25’ pad. If you’re still  unsure, just get in touch with us to get a helpful concrete pad diagram.</ListItemChecklist>
                                        
                                        <ListItemChecklist>Relocate any vehicles and large items away from your installation site in order to avoid a build-over fee. Remember, our installation crew needs at least 4’ of clearance all around your immediate building site in order to do their work properly.</ListItemChecklist>
                                        
                                        <ListItemChecklist>Have our payment ready before the arrival of the installation crew. We currently accept VISA, MasterCard, Discover, American Express, money orders, cashier’s checks, personal checks, or cash. Note that all credit card transactions will incur a 3% convenience fee.</ListItemChecklist>
                                    </ListChecklist>
                                </CardCheckBody>
                            </CardCheck>
                        </CheckListItem>
                    </CheckListGrid>
                </div>
            </Sections>
        </Layout>
    )

}
export default SitePreparationPage

export const pageQuery = graphql`
  query SitePreparationPageQuery{
    allContentfulPageData(filter: {page: {eq: "Site Preparation"}}) {
      edges {
        node {
          metaTitle
          metaDescription {
            metaDescription
          }
        }
      }
    }
  }
`